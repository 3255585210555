import { RouteRecordRaw } from 'vue-router'
import { createRootPath } from '@/entrypoints/utils/routerUtils'
import { Route } from '../base/config/routesConfig'

export const routes: RouteRecordRaw[] = [
  {
    path: createRootPath('404'),
    name: Route.NotFound,
    component: () => import('@/modules/app/views/404.vue'),
  },
]
