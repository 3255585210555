/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * global_changer_web
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateFootprintsDataRoomBody
} from '../../models/createFootprintsDataRoomBody'
import type {
  FootprintDataRoom
} from '../../models/footprintDataRoom'
import type {
  UpdateFootprintsDataRoomBody
} from '../../models/updateFootprintsDataRoomBody'
import { orvalFetch } from '../../utils/factory';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary index
 */
export type getFootprintsDataRoomsResponse = {
  data: FootprintDataRoom[] | string;
  status: number;
  headers: Headers;
}

export const getGetFootprintsDataRoomsUrl = (companyId: number,
    footprintId: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${footprintId}/data_rooms`
}

export const getFootprintsDataRooms = async (companyId: number,
    footprintId: number, options?: RequestInit): Promise<getFootprintsDataRoomsResponse> => {
  
  return orvalFetch<getFootprintsDataRoomsResponse>(getGetFootprintsDataRoomsUrl(companyId,footprintId),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export const getGetFootprintsDataRoomsQueryKey = (companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>,) => {
    return ['api','v0','companies',companyId,'footprints',footprintId,'data_rooms'] as const;
    }

    
export const getGetFootprintsDataRoomsQueryOptions = <TData = Awaited<ReturnType<typeof getFootprintsDataRooms>>, TError = string>(companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataRooms>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFootprintsDataRoomsQueryKey(companyId,footprintId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFootprintsDataRooms>>> = ({ signal }) => getFootprintsDataRooms(unref(companyId),unref(footprintId), { signal, ...requestOptions });

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(companyId) && unref(footprintId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataRooms>>, TError, TData> 
}

export type GetFootprintsDataRoomsQueryResult = NonNullable<Awaited<ReturnType<typeof getFootprintsDataRooms>>>
export type GetFootprintsDataRoomsQueryError = string


/**
 * @summary index
 */

export function useGetFootprintsDataRooms<TData = Awaited<ReturnType<typeof getFootprintsDataRooms>>, TError = string>(
 companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataRooms>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetFootprintsDataRoomsQueryOptions(companyId,footprintId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary create
 */
export type createFootprintsDataRoomResponse = {
  data: FootprintDataRoom | string;
  status: number;
  headers: Headers;
}

export const getCreateFootprintsDataRoomUrl = (companyId: number,
    footprintId: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${footprintId}/data_rooms`
}

export const createFootprintsDataRoom = async (companyId: number,
    footprintId: number,
    createFootprintsDataRoomBody: CreateFootprintsDataRoomBody, options?: RequestInit): Promise<createFootprintsDataRoomResponse> => {
  
  return orvalFetch<createFootprintsDataRoomResponse>(getCreateFootprintsDataRoomUrl(companyId,footprintId),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      createFootprintsDataRoomBody,)
  }
);}




export const getCreateFootprintsDataRoomMutationOptions = <TData = Awaited<ReturnType<typeof createFootprintsDataRoom>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;data: CreateFootprintsDataRoomBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['createFootprintsDataRoom'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFootprintsDataRoom>>, {companyId: number;footprintId: number;data: CreateFootprintsDataRoomBody}> = (props) => {
          const {companyId,footprintId,data} = props ?? {};

          return  createFootprintsDataRoom(companyId,footprintId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;footprintId: number;data: CreateFootprintsDataRoomBody}, TContext>}

    export type CreateFootprintsDataRoomMutationResult = NonNullable<Awaited<ReturnType<typeof createFootprintsDataRoom>>>
    export type CreateFootprintsDataRoomMutationBody = CreateFootprintsDataRoomBody
    export type CreateFootprintsDataRoomMutationError = string

    /**
 * @summary create
 */
export const useCreateFootprintsDataRoom = <TData = Awaited<ReturnType<typeof createFootprintsDataRoom>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;data: CreateFootprintsDataRoomBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;footprintId: number;data: CreateFootprintsDataRoomBody},
        TContext
      > => {

      const mutationOptions = getCreateFootprintsDataRoomMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary destroy
 */
export type deleteFootprintsDataRoomResponse = {
  data: void;
  status: number;
  headers: Headers;
}

export const getDeleteFootprintsDataRoomUrl = (companyId: number,
    footprintId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${footprintId}/data_rooms/${id}`
}

export const deleteFootprintsDataRoom = async (companyId: number,
    footprintId: number,
    id: number, options?: RequestInit): Promise<deleteFootprintsDataRoomResponse> => {
  
  return orvalFetch<deleteFootprintsDataRoomResponse>(getDeleteFootprintsDataRoomUrl(companyId,footprintId,id),
  {      
    ...options,
    method: 'DELETE'
    
    
  }
);}




export const getDeleteFootprintsDataRoomMutationOptions = <TData = Awaited<ReturnType<typeof deleteFootprintsDataRoom>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['deleteFootprintsDataRoom'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFootprintsDataRoom>>, {companyId: number;footprintId: number;id: number}> = (props) => {
          const {companyId,footprintId,id} = props ?? {};

          return  deleteFootprintsDataRoom(companyId,footprintId,id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number}, TContext>}

    export type DeleteFootprintsDataRoomMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFootprintsDataRoom>>>
    
    export type DeleteFootprintsDataRoomMutationError = unknown

    /**
 * @summary destroy
 */
export const useDeleteFootprintsDataRoom = <TData = Awaited<ReturnType<typeof deleteFootprintsDataRoom>>, TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;footprintId: number;id: number},
        TContext
      > => {

      const mutationOptions = getDeleteFootprintsDataRoomMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary show
 */
export type getFootprintsDataRoomResponse = {
  data: FootprintDataRoom | string;
  status: number;
  headers: Headers;
}

export const getGetFootprintsDataRoomUrl = (companyId: number,
    footprintId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${footprintId}/data_rooms/${id}`
}

export const getFootprintsDataRoom = async (companyId: number,
    footprintId: number,
    id: number, options?: RequestInit): Promise<getFootprintsDataRoomResponse> => {
  
  return orvalFetch<getFootprintsDataRoomResponse>(getGetFootprintsDataRoomUrl(companyId,footprintId,id),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export const getGetFootprintsDataRoomQueryKey = (companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>,
    id: MaybeRef<number>,) => {
    return ['api','v0','companies',companyId,'footprints',footprintId,'data_rooms',id] as const;
    }

    
export const getGetFootprintsDataRoomQueryOptions = <TData = Awaited<ReturnType<typeof getFootprintsDataRoom>>, TError = string>(companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>,
    id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataRoom>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFootprintsDataRoomQueryKey(companyId,footprintId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFootprintsDataRoom>>> = ({ signal }) => getFootprintsDataRoom(unref(companyId),unref(footprintId),unref(id), { signal, ...requestOptions });

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(companyId) && unref(footprintId) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataRoom>>, TError, TData> 
}

export type GetFootprintsDataRoomQueryResult = NonNullable<Awaited<ReturnType<typeof getFootprintsDataRoom>>>
export type GetFootprintsDataRoomQueryError = string


/**
 * @summary show
 */

export function useGetFootprintsDataRoom<TData = Awaited<ReturnType<typeof getFootprintsDataRoom>>, TError = string>(
 companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>,
    id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataRoom>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetFootprintsDataRoomQueryOptions(companyId,footprintId,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary update
 */
export type updateFootprintsDataRoomResponse = {
  data: FootprintDataRoom | string;
  status: number;
  headers: Headers;
}

export const getUpdateFootprintsDataRoomUrl = (companyId: number,
    footprintId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${footprintId}/data_rooms/${id}`
}

export const updateFootprintsDataRoom = async (companyId: number,
    footprintId: number,
    id: number,
    updateFootprintsDataRoomBody: UpdateFootprintsDataRoomBody, options?: RequestInit): Promise<updateFootprintsDataRoomResponse> => {
  
  return orvalFetch<updateFootprintsDataRoomResponse>(getUpdateFootprintsDataRoomUrl(companyId,footprintId,id),
  {      
    ...options,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      updateFootprintsDataRoomBody,)
  }
);}




export const getUpdateFootprintsDataRoomMutationOptions = <TData = Awaited<ReturnType<typeof updateFootprintsDataRoom>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number;data: UpdateFootprintsDataRoomBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['updateFootprintsDataRoom'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFootprintsDataRoom>>, {companyId: number;footprintId: number;id: number;data: UpdateFootprintsDataRoomBody}> = (props) => {
          const {companyId,footprintId,id,data} = props ?? {};

          return  updateFootprintsDataRoom(companyId,footprintId,id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number;data: UpdateFootprintsDataRoomBody}, TContext>}

    export type UpdateFootprintsDataRoomMutationResult = NonNullable<Awaited<ReturnType<typeof updateFootprintsDataRoom>>>
    export type UpdateFootprintsDataRoomMutationBody = UpdateFootprintsDataRoomBody
    export type UpdateFootprintsDataRoomMutationError = string

    /**
 * @summary update
 */
export const useUpdateFootprintsDataRoom = <TData = Awaited<ReturnType<typeof updateFootprintsDataRoom>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number;data: UpdateFootprintsDataRoomBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;footprintId: number;id: number;data: UpdateFootprintsDataRoomBody},
        TContext
      > => {

      const mutationOptions = getUpdateFootprintsDataRoomMutationOptions(options);

      return useMutation(mutationOptions);
    }
    