import { RouteRecordRaw } from 'vue-router'
import { getFootprintsDataPoint } from '@/api/endpoints/footprint-data-points/footprint-data-points'
import { getFootprintsDataRoom } from '@/api/endpoints/footprint-data-rooms/footprint-data-rooms'
import { getFootprint } from '@/api/endpoints/footprints/footprints'
import { createRootPath } from '@/entrypoints/utils/routerUtils'
import { Feature } from '../ability/composable/useFeatures'
import { Route } from '../base/config/routesConfig'

export const routes: RouteRecordRaw[] = [
  {
    path: createRootPath('footprint'),
    name: Route.Footprint,
    redirect: { name: Route.FootprintEntry },
    meta: {
      requireFeatures: [Feature.Footprint],
    },
    children: [
      {
        path: 'data-entry',
        name: Route.FootprintEntry,
        redirect: { name: Route.FootprintEntryDefault },
        meta: {
          requireFeatures: [Feature.FootprintDataEntry],
        },
        children: [
          {
            path: '',
            name: Route.FootprintEntryDefault,
            component: () =>
              import('@/modules/footprint/views/FootprintEntry.vue'),
          },
          {
            path: ':footprintId',
            name: Route.FootprintEntryYear,
            redirect: { name: Route.FootprintEntryYearDefault },
            meta: {
              companySwitchRedirect: { name: Route.FootprintEntry },
            },
            children: [
              {
                path: '',
                name: Route.FootprintEntryYearDefault,
                component: () =>
                  import('@/modules/footprint/views/FootprintEntryYear.vue'),
                meta: {
                  prefetchGuard(to) {
                    return [
                      getFootprint(
                        Number(to.params.companyId),
                        Number(to.params.footprintId)
                      ),
                    ]
                  },
                },
              },
              {
                path: ':dataPointId',
                name: Route.FootprintEntryYearPoint,
                component: () =>
                  import(
                    '@/modules/footprint/views/FootprintEntryYearPoint.vue'
                  ),
                meta: {
                  companySwitchRedirect: { name: Route.FootprintEntry },
                  prefetchGuard(to) {
                    return [
                      getFootprintsDataPoint(
                        Number(to.params.companyId),
                        Number(to.params.footprintId),
                        Number(to.params.dataPointId)
                      ),
                    ]
                  },
                },
              },
              {
                path: 'data-room',
                name: Route.FootprintDataRooms,
                redirect: { name: Route.FootprintEntryDefault },
                children: [
                  {
                    path: ':dataRoomId',
                    name: Route.FootprintDataRoom,
                    component: () =>
                      import('@/modules/footprint/views/FootprintDataRoom.vue'),
                    meta: {
                      companySwitchRedirect: { name: Route.FootprintEntry },
                      prefetchGuard(to) {
                        return [
                          getFootprintsDataRoom(
                            Number(to.params.companyId),
                            Number(to.params.footprintId),
                            Number(to.params.dataRoomId)
                          ),
                        ]
                      },
                    },
                  },
                ],
              },
              {
                path: 'bulk-assignments',
                name: Route.FootprintBulkAssignments,
                component: () =>
                  import(
                    '@/modules/footprint/views/FootprintEntryBulkAssignments.vue'
                  ),
                meta: {
                  requireFeatures: [Feature.BulkAssignment],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'result',
        name: Route.FootprintResult,
        component: () =>
          import('@/modules/footprint/views/FootprintResult.vue'),
        meta: {
          requireFeatures: [Feature.FootprintResult],
        },
      },
      {
        path: 'activities',
        name: Route.FootprintActivities,
        component: () =>
          import('@/modules/footprint/views/FootprintActivities.vue'),
        meta: {
          requireFeatures: [Feature.FootprintActivities],
        },
      },
    ],
  },
]
