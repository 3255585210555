/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * global_changer_web
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CompanyAction
} from '../../models/companyAction'
import type {
  CompanyActionRequestBody
} from '../../models/companyActionRequestBody'
import type {
  CompanyActionRequestError
} from '../../models/companyActionRequestError'
import type {
  ErrorResponse
} from '../../models/errorResponse'
import type {
  GetCompanyActionsParams
} from '../../models/getCompanyActionsParams'
import { orvalFetch } from '../../utils/factory';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary index
 */
export type getCompanyActionsResponse = {
  data: CompanyAction[] | void;
  status: number;
  headers: Headers;
}

export const getGetCompanyActionsUrl = (companyId: number,
    params?: GetCompanyActionsParams,) => {
  const normalizedParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    
    if (value !== undefined) {
      normalizedParams.append(key, value === null ? 'null' : value.toString())
    }
  });

  return normalizedParams.size ? `/api/v0/companies/${companyId}/company_actions?${normalizedParams.toString()}` : `/api/v0/companies/${companyId}/company_actions`
}

export const getCompanyActions = async (companyId: number,
    params?: GetCompanyActionsParams, options?: RequestInit): Promise<getCompanyActionsResponse> => {
  
  return orvalFetch<getCompanyActionsResponse>(getGetCompanyActionsUrl(companyId,params),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export const getGetCompanyActionsQueryKey = (companyId: MaybeRef<number>,
    params?: MaybeRef<GetCompanyActionsParams>,) => {
    return ['api','v0','companies',companyId,'company_actions', ...(params ? [params]: [])] as const;
    }

    
export const getGetCompanyActionsQueryOptions = <TData = Awaited<ReturnType<typeof getCompanyActions>>, TError = void>(companyId: MaybeRef<number>,
    params?: MaybeRef<GetCompanyActionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompanyActions>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetCompanyActionsQueryKey(companyId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyActions>>> = ({ signal }) => getCompanyActions(unref(companyId),unref(params), { signal, ...requestOptions });

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(companyId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCompanyActions>>, TError, TData> 
}

export type GetCompanyActionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyActions>>>
export type GetCompanyActionsQueryError = void


/**
 * @summary index
 */

export function useGetCompanyActions<TData = Awaited<ReturnType<typeof getCompanyActions>>, TError = void>(
 companyId: MaybeRef<number>,
    params?: MaybeRef<GetCompanyActionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompanyActions>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetCompanyActionsQueryOptions(companyId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary create
 */
export type createCompanyActionResponse = {
  data: CompanyAction | ErrorResponse | void | CompanyActionRequestError;
  status: number;
  headers: Headers;
}

export const getCreateCompanyActionUrl = (companyId: number,) => {


  return `/api/v0/companies/${companyId}/company_actions`
}

export const createCompanyAction = async (companyId: number,
    companyActionRequestBody: CompanyActionRequestBody, options?: RequestInit): Promise<createCompanyActionResponse> => {
  
  return orvalFetch<createCompanyActionResponse>(getCreateCompanyActionUrl(companyId),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      companyActionRequestBody,)
  }
);}




export const getCreateCompanyActionMutationOptions = <TData = Awaited<ReturnType<typeof createCompanyAction>>, TError = ErrorResponse | void | CompanyActionRequestError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;data: CompanyActionRequestBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['createCompanyAction'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCompanyAction>>, {companyId: number;data: CompanyActionRequestBody}> = (props) => {
          const {companyId,data} = props ?? {};

          return  createCompanyAction(companyId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;data: CompanyActionRequestBody}, TContext>}

    export type CreateCompanyActionMutationResult = NonNullable<Awaited<ReturnType<typeof createCompanyAction>>>
    export type CreateCompanyActionMutationBody = CompanyActionRequestBody
    export type CreateCompanyActionMutationError = ErrorResponse | void | CompanyActionRequestError

    /**
 * @summary create
 */
export const useCreateCompanyAction = <TData = Awaited<ReturnType<typeof createCompanyAction>>, TError = ErrorResponse | void | CompanyActionRequestError,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;data: CompanyActionRequestBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;data: CompanyActionRequestBody},
        TContext
      > => {

      const mutationOptions = getCreateCompanyActionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary destroy
 */
export type deleteCompanyActionResponse = {
  data: void | void;
  status: number;
  headers: Headers;
}

export const getDeleteCompanyActionUrl = (companyId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/company_actions/${id}`
}

export const deleteCompanyAction = async (companyId: number,
    id: number, options?: RequestInit): Promise<deleteCompanyActionResponse> => {
  
  return orvalFetch<deleteCompanyActionResponse>(getDeleteCompanyActionUrl(companyId,id),
  {      
    ...options,
    method: 'DELETE'
    
    
  }
);}




export const getDeleteCompanyActionMutationOptions = <TData = Awaited<ReturnType<typeof deleteCompanyAction>>, TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;id: number}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['deleteCompanyAction'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompanyAction>>, {companyId: number;id: number}> = (props) => {
          const {companyId,id} = props ?? {};

          return  deleteCompanyAction(companyId,id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;id: number}, TContext>}

    export type DeleteCompanyActionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompanyAction>>>
    
    export type DeleteCompanyActionMutationError = void

    /**
 * @summary destroy
 */
export const useDeleteCompanyAction = <TData = Awaited<ReturnType<typeof deleteCompanyAction>>, TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;id: number}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;id: number},
        TContext
      > => {

      const mutationOptions = getDeleteCompanyActionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary show
 */
export type getCompanyActionResponse = {
  data: CompanyAction | void;
  status: number;
  headers: Headers;
}

export const getGetCompanyActionUrl = (companyId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/company_actions/${id}`
}

export const getCompanyAction = async (companyId: number,
    id: number, options?: RequestInit): Promise<getCompanyActionResponse> => {
  
  return orvalFetch<getCompanyActionResponse>(getGetCompanyActionUrl(companyId,id),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export const getGetCompanyActionQueryKey = (companyId: MaybeRef<number>,
    id: MaybeRef<number>,) => {
    return ['api','v0','companies',companyId,'company_actions',id] as const;
    }

    
export const getGetCompanyActionQueryOptions = <TData = Awaited<ReturnType<typeof getCompanyAction>>, TError = void>(companyId: MaybeRef<number>,
    id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompanyAction>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetCompanyActionQueryKey(companyId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanyAction>>> = ({ signal }) => getCompanyAction(unref(companyId),unref(id), { signal, ...requestOptions });

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(companyId) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCompanyAction>>, TError, TData> 
}

export type GetCompanyActionQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyAction>>>
export type GetCompanyActionQueryError = void


/**
 * @summary show
 */

export function useGetCompanyAction<TData = Awaited<ReturnType<typeof getCompanyAction>>, TError = void>(
 companyId: MaybeRef<number>,
    id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCompanyAction>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetCompanyActionQueryOptions(companyId,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary update
 */
export type updateCompanyActionResponse = {
  data: CompanyAction | ErrorResponse | void;
  status: number;
  headers: Headers;
}

export const getUpdateCompanyActionUrl = (companyId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/company_actions/${id}`
}

export const updateCompanyAction = async (companyId: number,
    id: number,
    companyActionRequestBody: CompanyActionRequestBody, options?: RequestInit): Promise<updateCompanyActionResponse> => {
  
  return orvalFetch<updateCompanyActionResponse>(getUpdateCompanyActionUrl(companyId,id),
  {      
    ...options,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      companyActionRequestBody,)
  }
);}




export const getUpdateCompanyActionMutationOptions = <TData = Awaited<ReturnType<typeof updateCompanyAction>>, TError = ErrorResponse | void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;id: number;data: CompanyActionRequestBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['updateCompanyAction'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCompanyAction>>, {companyId: number;id: number;data: CompanyActionRequestBody}> = (props) => {
          const {companyId,id,data} = props ?? {};

          return  updateCompanyAction(companyId,id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;id: number;data: CompanyActionRequestBody}, TContext>}

    export type UpdateCompanyActionMutationResult = NonNullable<Awaited<ReturnType<typeof updateCompanyAction>>>
    export type UpdateCompanyActionMutationBody = CompanyActionRequestBody
    export type UpdateCompanyActionMutationError = ErrorResponse | void

    /**
 * @summary update
 */
export const useUpdateCompanyAction = <TData = Awaited<ReturnType<typeof updateCompanyAction>>, TError = ErrorResponse | void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;id: number;data: CompanyActionRequestBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;id: number;data: CompanyActionRequestBody},
        TContext
      > => {

      const mutationOptions = getUpdateCompanyActionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    