/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * global_changer_web
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateFootprintBody
} from '../../models/createFootprintBody'
import type {
  CreateFootprintsTransferBody
} from '../../models/createFootprintsTransferBody'
import type {
  Footprint
} from '../../models/footprint'
import type {
  UpdateFootprintBody
} from '../../models/updateFootprintBody'
import { orvalFetch } from '../../utils/factory';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary index
 */
export type getFootprintsResponse = {
  data: Footprint[] | string;
  status: number;
  headers: Headers;
}

export const getGetFootprintsUrl = (companyId: number,) => {


  return `/api/v0/companies/${companyId}/footprints`
}

export const getFootprints = async (companyId: number, options?: RequestInit): Promise<getFootprintsResponse> => {
  
  return orvalFetch<getFootprintsResponse>(getGetFootprintsUrl(companyId),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export const getGetFootprintsQueryKey = (companyId: MaybeRef<number>,) => {
    return ['api','v0','companies',companyId,'footprints'] as const;
    }

    
export const getGetFootprintsQueryOptions = <TData = Awaited<ReturnType<typeof getFootprints>>, TError = string>(companyId: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprints>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFootprintsQueryKey(companyId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFootprints>>> = ({ signal }) => getFootprints(unref(companyId), { signal, ...requestOptions });

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(companyId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFootprints>>, TError, TData> 
}

export type GetFootprintsQueryResult = NonNullable<Awaited<ReturnType<typeof getFootprints>>>
export type GetFootprintsQueryError = string


/**
 * @summary index
 */

export function useGetFootprints<TData = Awaited<ReturnType<typeof getFootprints>>, TError = string>(
 companyId: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprints>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetFootprintsQueryOptions(companyId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary create
 */
export type createFootprintResponse = {
  data: Footprint | string;
  status: number;
  headers: Headers;
}

export const getCreateFootprintUrl = (companyId: number,) => {


  return `/api/v0/companies/${companyId}/footprints`
}

export const createFootprint = async (companyId: number,
    createFootprintBody: CreateFootprintBody, options?: RequestInit): Promise<createFootprintResponse> => {
  
  return orvalFetch<createFootprintResponse>(getCreateFootprintUrl(companyId),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      createFootprintBody,)
  }
);}




export const getCreateFootprintMutationOptions = <TData = Awaited<ReturnType<typeof createFootprint>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;data: CreateFootprintBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['createFootprint'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFootprint>>, {companyId: number;data: CreateFootprintBody}> = (props) => {
          const {companyId,data} = props ?? {};

          return  createFootprint(companyId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;data: CreateFootprintBody}, TContext>}

    export type CreateFootprintMutationResult = NonNullable<Awaited<ReturnType<typeof createFootprint>>>
    export type CreateFootprintMutationBody = CreateFootprintBody
    export type CreateFootprintMutationError = string

    /**
 * @summary create
 */
export const useCreateFootprint = <TData = Awaited<ReturnType<typeof createFootprint>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;data: CreateFootprintBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;data: CreateFootprintBody},
        TContext
      > => {

      const mutationOptions = getCreateFootprintMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary show
 */
export type getFootprintResponse = {
  data: Footprint | string;
  status: number;
  headers: Headers;
}

export const getGetFootprintUrl = (companyId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${id}`
}

export const getFootprint = async (companyId: number,
    id: number, options?: RequestInit): Promise<getFootprintResponse> => {
  
  return orvalFetch<getFootprintResponse>(getGetFootprintUrl(companyId,id),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export const getGetFootprintQueryKey = (companyId: MaybeRef<number>,
    id: MaybeRef<number>,) => {
    return ['api','v0','companies',companyId,'footprints',id] as const;
    }

    
export const getGetFootprintQueryOptions = <TData = Awaited<ReturnType<typeof getFootprint>>, TError = string>(companyId: MaybeRef<number>,
    id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprint>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFootprintQueryKey(companyId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFootprint>>> = ({ signal }) => getFootprint(unref(companyId),unref(id), { signal, ...requestOptions });

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(companyId) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFootprint>>, TError, TData> 
}

export type GetFootprintQueryResult = NonNullable<Awaited<ReturnType<typeof getFootprint>>>
export type GetFootprintQueryError = string


/**
 * @summary show
 */

export function useGetFootprint<TData = Awaited<ReturnType<typeof getFootprint>>, TError = string>(
 companyId: MaybeRef<number>,
    id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprint>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetFootprintQueryOptions(companyId,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary update
 */
export type updateFootprintResponse = {
  data: Footprint | string;
  status: number;
  headers: Headers;
}

export const getUpdateFootprintUrl = (companyId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${id}`
}

export const updateFootprint = async (companyId: number,
    id: number,
    updateFootprintBody: UpdateFootprintBody, options?: RequestInit): Promise<updateFootprintResponse> => {
  
  return orvalFetch<updateFootprintResponse>(getUpdateFootprintUrl(companyId,id),
  {      
    ...options,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      updateFootprintBody,)
  }
);}




export const getUpdateFootprintMutationOptions = <TData = Awaited<ReturnType<typeof updateFootprint>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;id: number;data: UpdateFootprintBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['updateFootprint'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFootprint>>, {companyId: number;id: number;data: UpdateFootprintBody}> = (props) => {
          const {companyId,id,data} = props ?? {};

          return  updateFootprint(companyId,id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;id: number;data: UpdateFootprintBody}, TContext>}

    export type UpdateFootprintMutationResult = NonNullable<Awaited<ReturnType<typeof updateFootprint>>>
    export type UpdateFootprintMutationBody = UpdateFootprintBody
    export type UpdateFootprintMutationError = string

    /**
 * @summary update
 */
export const useUpdateFootprint = <TData = Awaited<ReturnType<typeof updateFootprint>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;id: number;data: UpdateFootprintBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;id: number;data: UpdateFootprintBody},
        TContext
      > => {

      const mutationOptions = getUpdateFootprintMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary transfer
 */
export type createFootprintsTransferResponse = {
  data: void | void | string;
  status: number;
  headers: Headers;
}

export const getCreateFootprintsTransferUrl = (companyId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${id}/transfer`
}

export const createFootprintsTransfer = async (companyId: number,
    id: number,
    createFootprintsTransferBody: CreateFootprintsTransferBody, options?: RequestInit): Promise<createFootprintsTransferResponse> => {
  
  return orvalFetch<createFootprintsTransferResponse>(getCreateFootprintsTransferUrl(companyId,id),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      createFootprintsTransferBody,)
  }
);}




export const getCreateFootprintsTransferMutationOptions = <TData = Awaited<ReturnType<typeof createFootprintsTransfer>>, TError = void | string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;id: number;data: CreateFootprintsTransferBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['createFootprintsTransfer'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFootprintsTransfer>>, {companyId: number;id: number;data: CreateFootprintsTransferBody}> = (props) => {
          const {companyId,id,data} = props ?? {};

          return  createFootprintsTransfer(companyId,id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;id: number;data: CreateFootprintsTransferBody}, TContext>}

    export type CreateFootprintsTransferMutationResult = NonNullable<Awaited<ReturnType<typeof createFootprintsTransfer>>>
    export type CreateFootprintsTransferMutationBody = CreateFootprintsTransferBody
    export type CreateFootprintsTransferMutationError = void | string

    /**
 * @summary transfer
 */
export const useCreateFootprintsTransfer = <TData = Awaited<ReturnType<typeof createFootprintsTransfer>>, TError = void | string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;id: number;data: CreateFootprintsTransferBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;id: number;data: CreateFootprintsTransferBody},
        TContext
      > => {

      const mutationOptions = getCreateFootprintsTransferMutationOptions(options);

      return useMutation(mutationOptions);
    }
    