import { RouteRecordRaw } from 'vue-router'
import { getCompanyAction } from '@/api/endpoints/company-actions/company-actions'
import { Feature } from '@/modules/ability/composable/useFeatures'
import { Route, RouteHistoryState } from '@/modules/base/config/routesConfig'
import { createRootPath } from './utils/routerUtils'

interface RouteModule {
  routes: RouteRecordRaw[]
}

const moduleRoutes = import.meta.glob('../modules/*/routes.ts', { eager: true })
const moduleRoutesFlat: RouteRecordRaw[] = Object.values(moduleRoutes).flatMap(
  (module) => (module as RouteModule).routes
)

const routes: RouteRecordRaw[] = [
  ...moduleRoutesFlat,
  // todo move other routes to their modules nad adjust hygen generator
  // hygen generated routes (don't remove this comment)
  {
    path: createRootPath('onboarding'),
    name: Route.Onboarding,
    component: () => import('@/modules/onboarding/views/Onboarding.vue'),
    children: [
      {
        path: 'welcome',
        name: Route.OnboardingWelcome,
        component: () =>
          import('@/modules/onboarding/views/OnboardingWelcome.vue'),
      },
      {
        path: 'actions/manual',
        name: Route.OnboardingActionsManual,
        component: () =>
          import('@/modules/onboarding/views/OnboardingActionsManual.vue'),
      },
      {
        path: 'actions/quick',
        name: Route.OnboardingActionsQuick,
        component: () =>
          import('@/modules/onboarding/views/OnboardingActionsQuick.vue'),
      },
      {
        path: 'finishing',
        name: Route.OnboardingFinishing,
        component: () =>
          import('@/modules/onboarding/views/OnboardingFinishing.vue'),
      },
    ],
  },
  {
    path: createRootPath('action/:id'),
    name: Route.Action,
    component: () => import('@/modules/action/views/Action.vue'),
    meta: {
      requireFeatures: [Feature.ReductionRoadmap],
      companySwitchRedirect: { name: Route.ActionsRoadmap },
      prefetchGuard(to) {
        return [
          getCompanyAction(Number(to.params.companyId), Number(to.params.id)),
        ]
      },
    },
  },
  {
    path: createRootPath('actions'),
    name: Route.Actions,
    component: () => import('@/modules/actions/views/Actions.vue'),
    redirect: { name: Route.ActionsRoadmap },
    meta: {
      historyStateKey: RouteHistoryState.Actions,
      requireFeatures: [Feature.ReductionRoadmap],
    },
    children: [
      {
        path: 'roadmap',
        name: Route.ActionsRoadmap,
        component: () => import('@/modules/actions/views/ActionsRoadmap.vue'),
        meta: {
          scrollTo: 'element',
        },
      },
      {
        path: 'roadmap/:year',
        name: Route.ActionsRoadmapQuarters,
        component: () =>
          import('@/modules/actions/views/ActionsRoadmapByQuarter.vue'),
      },
    ],
  },
  // hygen end
  {
    path: createRootPath('dashboard'),
    alias: '/dashboard',
    name: Route.Dashboard,
    meta: {
      historyStateKey: RouteHistoryState.Dashboard,
      requireFeatures: [Feature.StatusBoard],
    },
    component: () => import('@/modules/dashboard/views/Dashboard.vue'),
  },
  {
    path: createRootPath(''),
    alias: '/my-tasks',
    name: Route.MyTasks,
    component: () => import('@/modules/myTasks/views/MyTasks.vue'),
  },
  {
    path: createRootPath('not-onboarded'),
    name: Route.NotOnboarded,
    component: () => import('@/modules/onboarding/views/NotOnboarded.vue'),
  },
  {
    path: createRootPath(':pathMatch(.*)*'),
    redirect: { name: Route.NotFound },
  },
]

export default routes
