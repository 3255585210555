/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * global_changer_web
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateFootprintsDataPointsSetDueRuleBody
} from '../../models/createFootprintsDataPointsSetDueRuleBody'
import type {
  ErrorResponse
} from '../../models/errorResponse'
import type {
  FootprintDataPoint
} from '../../models/footprintDataPoint'
import type {
  FootprintDataPointRequestBody
} from '../../models/footprintDataPointRequestBody'
import type {
  UpdateFootprintsDataPointBody
} from '../../models/updateFootprintsDataPointBody'
import { orvalFetch } from '../../utils/factory';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary index
 */
export type getFootprintsDataPointsResponse = {
  data: FootprintDataPoint[] | string;
  status: number;
  headers: Headers;
}

export const getGetFootprintsDataPointsUrl = (companyId: number,
    footprintId: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${footprintId}/data_points`
}

export const getFootprintsDataPoints = async (companyId: number,
    footprintId: number, options?: RequestInit): Promise<getFootprintsDataPointsResponse> => {
  
  return orvalFetch<getFootprintsDataPointsResponse>(getGetFootprintsDataPointsUrl(companyId,footprintId),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export const getGetFootprintsDataPointsQueryKey = (companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>,) => {
    return ['api','v0','companies',companyId,'footprints',footprintId,'data_points'] as const;
    }

    
export const getGetFootprintsDataPointsQueryOptions = <TData = Awaited<ReturnType<typeof getFootprintsDataPoints>>, TError = string>(companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataPoints>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFootprintsDataPointsQueryKey(companyId,footprintId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFootprintsDataPoints>>> = ({ signal }) => getFootprintsDataPoints(unref(companyId),unref(footprintId), { signal, ...requestOptions });

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(companyId) && unref(footprintId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataPoints>>, TError, TData> 
}

export type GetFootprintsDataPointsQueryResult = NonNullable<Awaited<ReturnType<typeof getFootprintsDataPoints>>>
export type GetFootprintsDataPointsQueryError = string


/**
 * @summary index
 */

export function useGetFootprintsDataPoints<TData = Awaited<ReturnType<typeof getFootprintsDataPoints>>, TError = string>(
 companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataPoints>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetFootprintsDataPointsQueryOptions(companyId,footprintId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary create
 */
export type createFootprintsDataPointResponse = {
  data: FootprintDataPoint | ErrorResponse | string;
  status: number;
  headers: Headers;
}

export const getCreateFootprintsDataPointUrl = (companyId: number,
    footprintId: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${footprintId}/data_points`
}

export const createFootprintsDataPoint = async (companyId: number,
    footprintId: number,
    footprintDataPointRequestBody: FootprintDataPointRequestBody, options?: RequestInit): Promise<createFootprintsDataPointResponse> => {
  
  return orvalFetch<createFootprintsDataPointResponse>(getCreateFootprintsDataPointUrl(companyId,footprintId),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      footprintDataPointRequestBody,)
  }
);}




export const getCreateFootprintsDataPointMutationOptions = <TData = Awaited<ReturnType<typeof createFootprintsDataPoint>>, TError = ErrorResponse | string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;data: FootprintDataPointRequestBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['createFootprintsDataPoint'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFootprintsDataPoint>>, {companyId: number;footprintId: number;data: FootprintDataPointRequestBody}> = (props) => {
          const {companyId,footprintId,data} = props ?? {};

          return  createFootprintsDataPoint(companyId,footprintId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;footprintId: number;data: FootprintDataPointRequestBody}, TContext>}

    export type CreateFootprintsDataPointMutationResult = NonNullable<Awaited<ReturnType<typeof createFootprintsDataPoint>>>
    export type CreateFootprintsDataPointMutationBody = FootprintDataPointRequestBody
    export type CreateFootprintsDataPointMutationError = ErrorResponse | string

    /**
 * @summary create
 */
export const useCreateFootprintsDataPoint = <TData = Awaited<ReturnType<typeof createFootprintsDataPoint>>, TError = ErrorResponse | string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;data: FootprintDataPointRequestBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;footprintId: number;data: FootprintDataPointRequestBody},
        TContext
      > => {

      const mutationOptions = getCreateFootprintsDataPointMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary show
 */
export type getFootprintsDataPointResponse = {
  data: FootprintDataPoint | string;
  status: number;
  headers: Headers;
}

export const getGetFootprintsDataPointUrl = (companyId: number,
    footprintId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${footprintId}/data_points/${id}`
}

export const getFootprintsDataPoint = async (companyId: number,
    footprintId: number,
    id: number, options?: RequestInit): Promise<getFootprintsDataPointResponse> => {
  
  return orvalFetch<getFootprintsDataPointResponse>(getGetFootprintsDataPointUrl(companyId,footprintId,id),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export const getGetFootprintsDataPointQueryKey = (companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>,
    id: MaybeRef<number>,) => {
    return ['api','v0','companies',companyId,'footprints',footprintId,'data_points',id] as const;
    }

    
export const getGetFootprintsDataPointQueryOptions = <TData = Awaited<ReturnType<typeof getFootprintsDataPoint>>, TError = string>(companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>,
    id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataPoint>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFootprintsDataPointQueryKey(companyId,footprintId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFootprintsDataPoint>>> = ({ signal }) => getFootprintsDataPoint(unref(companyId),unref(footprintId),unref(id), { signal, ...requestOptions });

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(companyId) && unref(footprintId) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataPoint>>, TError, TData> 
}

export type GetFootprintsDataPointQueryResult = NonNullable<Awaited<ReturnType<typeof getFootprintsDataPoint>>>
export type GetFootprintsDataPointQueryError = string


/**
 * @summary show
 */

export function useGetFootprintsDataPoint<TData = Awaited<ReturnType<typeof getFootprintsDataPoint>>, TError = string>(
 companyId: MaybeRef<number>,
    footprintId: MaybeRef<number>,
    id: MaybeRef<number>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFootprintsDataPoint>>, TError, TData>>, request?: SecondParameter<typeof orvalFetch>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetFootprintsDataPointQueryOptions(companyId,footprintId,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary update
 */
export type updateFootprintsDataPointResponse = {
  data: FootprintDataPoint | string;
  status: number;
  headers: Headers;
}

export const getUpdateFootprintsDataPointUrl = (companyId: number,
    footprintId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${footprintId}/data_points/${id}`
}

export const updateFootprintsDataPoint = async (companyId: number,
    footprintId: number,
    id: number,
    updateFootprintsDataPointBody: UpdateFootprintsDataPointBody, options?: RequestInit): Promise<updateFootprintsDataPointResponse> => {
  
  return orvalFetch<updateFootprintsDataPointResponse>(getUpdateFootprintsDataPointUrl(companyId,footprintId,id),
  {      
    ...options,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      updateFootprintsDataPointBody,)
  }
);}




export const getUpdateFootprintsDataPointMutationOptions = <TData = Awaited<ReturnType<typeof updateFootprintsDataPoint>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number;data: UpdateFootprintsDataPointBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['updateFootprintsDataPoint'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFootprintsDataPoint>>, {companyId: number;footprintId: number;id: number;data: UpdateFootprintsDataPointBody}> = (props) => {
          const {companyId,footprintId,id,data} = props ?? {};

          return  updateFootprintsDataPoint(companyId,footprintId,id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number;data: UpdateFootprintsDataPointBody}, TContext>}

    export type UpdateFootprintsDataPointMutationResult = NonNullable<Awaited<ReturnType<typeof updateFootprintsDataPoint>>>
    export type UpdateFootprintsDataPointMutationBody = UpdateFootprintsDataPointBody
    export type UpdateFootprintsDataPointMutationError = string

    /**
 * @summary update
 */
export const useUpdateFootprintsDataPoint = <TData = Awaited<ReturnType<typeof updateFootprintsDataPoint>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number;data: UpdateFootprintsDataPointBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;footprintId: number;id: number;data: UpdateFootprintsDataPointBody},
        TContext
      > => {

      const mutationOptions = getUpdateFootprintsDataPointMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary set_due_rule
 */
export type createFootprintsDataPointsSetDueRuleResponse = {
  data: FootprintDataPoint | string;
  status: number;
  headers: Headers;
}

export const getCreateFootprintsDataPointsSetDueRuleUrl = (companyId: number,
    footprintId: number,
    id: number,) => {


  return `/api/v0/companies/${companyId}/footprints/${footprintId}/data_points/${id}/set_due_rule`
}

export const createFootprintsDataPointsSetDueRule = async (companyId: number,
    footprintId: number,
    id: number,
    createFootprintsDataPointsSetDueRuleBody: CreateFootprintsDataPointsSetDueRuleBody, options?: RequestInit): Promise<createFootprintsDataPointsSetDueRuleResponse> => {
  
  return orvalFetch<createFootprintsDataPointsSetDueRuleResponse>(getCreateFootprintsDataPointsSetDueRuleUrl(companyId,footprintId,id),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      createFootprintsDataPointsSetDueRuleBody,)
  }
);}




export const getCreateFootprintsDataPointsSetDueRuleMutationOptions = <TData = Awaited<ReturnType<typeof createFootprintsDataPointsSetDueRule>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number;data: CreateFootprintsDataPointsSetDueRuleBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
) => {
const mutationKey = ['createFootprintsDataPointsSetDueRule'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFootprintsDataPointsSetDueRule>>, {companyId: number;footprintId: number;id: number;data: CreateFootprintsDataPointsSetDueRuleBody}> = (props) => {
          const {companyId,footprintId,id,data} = props ?? {};

          return  createFootprintsDataPointsSetDueRule(companyId,footprintId,id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions } as UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number;data: CreateFootprintsDataPointsSetDueRuleBody}, TContext>}

    export type CreateFootprintsDataPointsSetDueRuleMutationResult = NonNullable<Awaited<ReturnType<typeof createFootprintsDataPointsSetDueRule>>>
    export type CreateFootprintsDataPointsSetDueRuleMutationBody = CreateFootprintsDataPointsSetDueRuleBody
    export type CreateFootprintsDataPointsSetDueRuleMutationError = string

    /**
 * @summary set_due_rule
 */
export const useCreateFootprintsDataPointsSetDueRule = <TData = Awaited<ReturnType<typeof createFootprintsDataPointsSetDueRule>>, TError = string,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<TData, TError,{companyId: number;footprintId: number;id: number;data: CreateFootprintsDataPointsSetDueRuleBody}, TContext>, request?: SecondParameter<typeof orvalFetch>}
): UseMutationReturnType<
        TData,
        TError,
        {companyId: number;footprintId: number;id: number;data: CreateFootprintsDataPointsSetDueRuleBody},
        TContext
      > => {

      const mutationOptions = getCreateFootprintsDataPointsSetDueRuleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    